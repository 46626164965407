const SvgInbox = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fill="#fff"
    viewBox="0 0 366.785 366.785"
    height="100%"
    role="img"
    {...props}
  >
    <path d="M337.536 178.956h-3.85V77.648c0-15.401-12.53-27.932-27.932-27.932H61.032c-15.401 0-27.932 12.53-27.932 27.932v101.307h-3.85c-16.129 0-29.25 13.121-29.25 29.25v79.613c0 16.129 13.121 29.25 29.25 29.25h308.285c16.129 0 29.25-13.121 29.25-29.25v-79.613c.001-16.129-13.12-29.249-29.249-29.249M48.101 77.648c0-7.131 5.801-12.932 12.932-12.932h244.723c7.131 0 12.932 5.801 12.932 12.932v1.696l-135.293 86.55L48.101 79.345zm37.345 127.377 65.642-41.991 28.264 18.081a7.5 7.5 0 0 0 4.042 1.183c1.405 0 2.81-.394 4.042-1.183l28.264-18.081 65.642 41.991a30 30 0 0 0-.176 3.18v19.348H85.622v-19.348c0-1.074-.062-2.135-.176-3.18m144.17-50.894 89.07-56.979v81.804h-8.271c-9.611 0-18.152 4.66-23.488 11.839zM48.101 97.151l89.07 56.979-57.312 36.663c-5.336-7.179-13.877-11.839-23.488-11.839h-8.27zm303.685 190.667c0 7.857-6.393 14.25-14.25 14.25H29.251c-7.857 0-14.25-6.393-14.25-14.25v-79.613c0-7.857 6.393-14.25 14.25-14.25h27.12c7.858 0 14.251 6.393 14.251 14.25v26.848c0 4.143 3.357 7.5 7.5 7.5h210.543c4.143 0 7.5-3.357 7.5-7.5v-26.848c0-7.857 6.393-14.25 14.251-14.25h27.12c7.857 0 14.25 6.393 14.25 14.25z" />
  </svg>
);
export default SvgInbox;
